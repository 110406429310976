import React from 'react';
import LoginContainer from '../containers/LoginContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.EstimationVariantInfos.url;
const Step5Login = () => (
  <Layout slug="EstimationVariantLogin">
    <LoginContainer nextStep={nextStep} />;
  </Layout>
);
export default Step5Login;
